import(/* webpackMode: "eager" */ "/home/runner/work/about.jinsu.kim/about.jinsu.kim/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/about.jinsu.kim/about.jinsu.kim/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/about.jinsu.kim/about.jinsu.kim/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/about.jinsu.kim/about.jinsu.kim/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/about.jinsu.kim/about.jinsu.kim/src/core/index.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/about.jinsu.kim/about.jinsu.kim/node_modules/pretendard/dist/web/variable/pretendardvariable-dynamic-subset.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/about.jinsu.kim/about.jinsu.kim/node_modules/the-new-css-reset/css/reset.css");
